import { Identifiable } from '@core/shared/data-access';

import { Country } from './country';

export enum SupplierAddressType {
  MainAddress = 1,
  SubAddress = 2,
  PostOfficeBox = 3,
}

export interface SupplierAddress extends Identifiable<string> {
  type: SupplierAddressType;
  street?: string;
  zipCode?: string;
  city?: string;
  country?: Country;
  gln?: string;
}

// TODO: EnumDescriptions so ähnlich wie im Import
export const supplierAddressTypeDescriptions: Readonly<Record<SupplierAddressType, string>> = {
  [SupplierAddressType.MainAddress]: 'Hauptanschrift',
  [SupplierAddressType.SubAddress]: 'Nebenanschrift',
  [SupplierAddressType.PostOfficeBox]: 'Postfach',
};
